<template>
  <el-popover width="320" trigger="click" popper-class="popoverCleanMap">
    <button slot="reference" class="transition-colors duration-300 bg-white h-9 w-9 flex items-center justify-center button" type="button">
      <GearIcon />
    </button>
    <p class="mt-2 ml-4 mb-4" style="font-size: 14px; color: #9cbad6">
      {{ $t('hide.title') }}
    </p>
    <div v-for="(item, index) in layers" :key="index" class="flex py-4 container full">
      <div class="flex flex-col w-full mx-4">
        <p v-if="item.name" class="item-title mb-4">
          {{ item.name }}
        </p>
        <div class="flex w-full justify-between" :style="[item.group ? { 'flex-direction': 'column' } : { 'flex-direction': 'row' }]">
          <p class="item-text">
            {{ item.text }}
          </p>
          <div v-if="item.group" class="flex items-center mt-2">
            <div class="flex items-center cluster-radius">
              <span @click="changeClusterRadius(true)">-</span>
              <p>{{ clusterRadiusCount }}</p>
              <span @click="changeClusterRadius(false)">+</span>
            </div>
            <p class="ml-3 item-text">
              {{ $t('pixels.group') }}
            </p>
          </div>
          <skif-switch v-else-if="item.signatures" v-model="toggleTrek" class="item-button" @click.native.stop @change="hideTrekInfo">
            {{ $t('hide') }}
          </skif-switch>
          <skif-switch v-else-if="item.isGeozone" v-model="toggleGeozones" class="item-button" @click.native.stop @change="hideGeozones">
            {{ $t('hide') }}
          </skif-switch>
          <skif-switch v-else-if="item.isEvents" v-model="toggleEvents" class="item-button" @click.native.stop @change="hideEvents">
            {{ $t('hide') }}
          </skif-switch>
          <skif-switch v-else-if="item.isNotification" v-model="toggleNotification" class="item-button" @click.native.stop @change="hideNotification">
            {{ $t('hide') }}
          </skif-switch>
          <button v-else class="item-button" @click="$store.commit('map/EYE_TRIGGER', item.value)">
            {{ $t('hide') }}
          </button>
        </div>
      </div>
    </div>
  </el-popover>
</template>

<script>
// //- @click='$store.commit("map/EYE_TRIGGER")' used to clean the map
import { mapMutations, mapGetters, mapState } from 'vuex'
import * as L from 'leaflet'
import GearIcon from '@/components/map/tools/images/GearIcon.vue'

export default {
  components: {
    GearIcon
  },
  data() {
    return {
      toggleGeozones: '',
      toggleEvents: '',
      addRadiusValue: '',
      toggleTrek: false,
      clusterRadiusCount: 100,
      toggleNotification: '',
      layers: [
        {
          name: this.$t('hide.objects'),
          text: this.$t('hide.objects.text'),
          value: 'objects'
        },
        {
          text: this.$t('hide.tracks.group'),
          group: true
        },
        {
          name: this.$t('hide.tracks'),
          text: this.$t('hide.tracks.text'),
          value: 'tracks'
        },
        {
          text: this.$t('hide.tracks.signatures'),
          signatures: true
        },
        {
          name: this.$t('hide.notifications'),
          text: this.$t('hide.notifications.text'),
          isNotification: true
        },
        {
          name: this.$t('hide.geozones'),
          text: this.$t('hide.geozones.text'),
          isGeozone: true
        },
        {
          name: this.$t('hide.events'),
          text: this.$t('hide.events.text'),
          isEvents: true
        },
        // {
        //   name: this.$t('hide.trips'),
        //   text: this.$t('hide.trips.text'),
        //   value: 'trips',
        // },
        {
          name: this.$t('hide.all'),
          text: this.$t('hide.all.text'),
          value: 'all'
        }
      ]
    }
  },
  computed: {
    ...mapState('map', {
      isHiding: state => state.isHidingGeo,
      isHidingEvents: state => state.isHidingEvents,
      isHidingNotification: state => state.isHidingNotification
    }),
    ...mapGetters('map', ['vuexRadiusValue'])
  },
  watch: {
    // isHiding(val) {
    //   this.toggleGeozones = val;
    // },
    isHidingEvents(val) {
      this.toggleEvents = val
    },
    isHidingNotification(val) {
      this.toggleNotification = val
    },
    vuexRadiusValue(val) {
      this.clusterRadiusCount = val
    }
  },
  created() {
    this.toggleGeozones = this.isHiding
    this.toggleEvents = JSON.parse(localStorage.getItem('hideevents'))
    this.toggleNotification = JSON.parse(localStorage.getItem('hideNotification'))
    // this.$store.commit('map/TOGGLE_EVENTS', this.toggleEvents)
    this.$store.commit('map/TOGGLE_NOTIFICATION', this.toggleNotification)
  },
  mounted() {
    const clusterRadiusCount = localStorage.getItem('clusterRadiusCount')

    if (clusterRadiusCount) {
      this.clusterRadiusCount = Number(clusterRadiusCount)
    }
  },
  methods: {
    ...mapMutations('map', ['SET_RADIUS_VALUE']),
    changeClusterRadius(value) {
      if (value && +this.clusterRadiusCount > 10) {
        this.clusterRadiusCount -= 10
      } else if (!value && +this.clusterRadiusCount < 100) {
        this.clusterRadiusCount += 10
      }

      this.SET_RADIUS_VALUE(this.clusterRadiusCount)

      localStorage.setItem('clusterRadiusCount', this.clusterRadiusCount)
    },
    hideGeozones() {
      console.log(2)

      localStorage.setItem('hidegeozones', JSON.stringify(this.toggleGeozones))
      this.$store.commit('map/TOGGLE_GEOZONES', this.toggleGeozones)
    },
    hideTrekInfo() {
      this.$store.commit('map/TOGGLE_TREK_INFO', this.toggleTrek)
    },
    hideEvents() {
      localStorage.setItem('hideevents', JSON.stringify(this.toggleEvents))
      this.$store.commit('map/TOGGLE_EVENTS', this.toggleEvents)
    },
    hideNotification() {
      console.log('hid notif', this.toggleNotification)
      localStorage.setItem('hideNotification', JSON.stringify(this.toggleNotification))
      this.$store.commit('map/TOGGLE_NOTIFICATION', this.toggleNotification)
    }
  }
}
</script>
<style>
.popoverCleanMap {
  padding: 12px 0 0 0;
  border-radius: 10px;
}
</style>
<style scoped>
.button {
  margin-right: 20px;
  outline: none;
}
.button:focus {
  outline: none;
}
.item-title,
.item-button {
  color: #5477a9;
  font-weight: 700;
  outline: none;
}
.item-text {
  color: #677785;
  font-weight: 400;
}
.item-button {
  font-size: 12px;
}
.item-button:hover {
  color: #08326d;
}
.container,
.item-button {
  transition: all 0.3s ease;
}
.container:hover {
  background-color: #f7fbff;
}
.cluster-radius {
  width: fit-content;
  height: 33px;
  border: 1px solid #d1dbe4;
  border-radius: 5px;
}
.cluster-radius span {
  position: relative;
  padding: 0 15px;
  color: #5477a9;
  cursor: pointer;
}
.cluster-radius span:before {
  content: '';
  position: absolute;
  top: -7px;
  width: 1px;
  height: 33px;
  background: #d1dbe4;
}
.cluster-radius span:first-child:before {
  right: 0;
}
.cluster-radius span:last-child:before {
  left: 0;
}
.cluster-radius p {
  padding: 0 18px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #333333;
}
</style>
